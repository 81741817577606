<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-16 15:07:36
 * @LastEditors: ------
 * @LastEditTime: 2022-02-17 10:47:43
-->
<template>
  <div>
    <van-nav-bar
      title="支付方式"
      left-text="返回"
      fixed
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <van-form @submit="onSubmit">
      <van-field
        label="合同类型"
        value="按月结算"
        input-align="right"
        disabled
      />
      <van-field
        v-model="value"
        name="orderPrice"
        label="劳务费单价"
        input-align="right"
        type="number"
        placeholder="请输入单价"
        :rules="[{ required: true }]"
      >
        <template #button>
          <van-button size="small" style="border: none" disabled
            >元/月</van-button
          >
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="orderFinalPayDate"
        :value="value1"
        label="付款周期"
        placeholder="每月/号付款"
        input-align="right"
        @click="showPicker1 = true"
        :rules="[{ required: true }]"
      />

      <van-popup
        v-model="showPicker1"
        :rules="[{ required: true }]"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>

      <van-field name="checkboxGroup">
        <template #input>
          <van-checkbox-group v-model="checkboxGroup">
            <van-checkbox name="1" shape="square" checked-color="#FF9A31"
              >雇主同意购买并签订《三方责任险》</van-checkbox
            >
            <van-checkbox name="2" shape="square" checked-color="#FF9A31"
              >雇主同意购买并签订《雇主责任险》</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field>
      <div style="margin: 16px" class="btn">
        <van-button
          round
          block
          color="linear-gradient(-90deg, #FF9A31, #FFD385)"
          native-type="submit"
          >生成服务合同</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import { postContract } from "@/api/index.js";
export default {
  name: "",
  data() {
    return {
      num: "",
      value: "",
      value1: "",
      showPicker1: false,
      checkbox: false,
      checkboxGroup: [],
      columns: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],

      objData: null,
    };
  },
  created() {
    this.objData = JSON.parse(this.$route.query.obj);
  },
  methods: {
    async onSubmit(values) {
      if (values.checkboxGroup.length < 1) return Toast("请同意购买责任险！");
      if (this.Object == "") return Toast("此前页面信息未填写！");
      values.totalAmount = this.objData.num * values.orderPrice;
      values.flag = "0";
      values.monthAmount = values.orderPrice;
      values.storeId = JSON.parse(localStorage.getItem("info")).storeId;
      values.salesman = JSON.parse(localStorage.getItem("info")).id;
      values = Object.assign(this.objData, values);

      let { data } = await postContract(values);

      if (data.success) {
        Toast.success(data.message);
        this.$router.push("/manage");
      } else {
        Toast.fail(data.message);
      }
    },
    onConfirm1(time) {
      this.value1 = time;
      this.showPicker1 = false;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar {
  background: #ff9a31;

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon,
  .van-nav-bar__text {
    color: #fff;
  }
}
/deep/ .van-field__control {
  div {
    margin-top: 3px;
  }
  .van-checkbox__label {
    margin-top: 5px;
  }
}
.btn {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
